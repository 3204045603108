import '../assets/js/jscrollpane.min'
import 'jquery-placeholder'
import 'groupinputs'
import '../assets/js/hoverintent.min'
import MobileDetect from 'mobile-detect'
import '../assets/js/magnific-popup.min'
import '../assets/js/parsley'
import '../assets/js/jquery.touchswipe.min'
import $ from 'jquery'
import { app, initApp } from '../assets/js/common'

$.fn.removeStyle = function (style) {
  const search = new RegExp(style + '[^;]+;?', 'g')
  return this.each(function () {
    $(this).attr('style', function (i, style) {
      return style.replace(search, '')
    })
  })
}

window.onNuxtReady(({ $store }) => {
  // console.log('Nuxt.js is ready and mounted')
  // eslint-disable-next-line no-undef
  window.md = window.md || new MobileDetect(window.navigator.userAgent)
  window.mobile = window.mobile || (window.md.mobile() && $(window).width() < 768)

  app.base()
  initApp(null, $store)
  app.prepared = true

  const notShowActionPanel = false

  // top action panel
  $(window).scroll(function () {
    $('.top-actions-panel').each(function (i) {
      const bottomOfObject = $(this).position().top + $(this).outerHeight()

      if (bottomOfObject > 270 && notShowActionPanel !== true) {
        $(this).show()
      } else { $(this).hide() }
    })
  })

  $store.dispatch('favorite/loadFromStore')

  setTimeout(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//code.jivosite.com/widget.js'
    script.setAttribute('data-jv-id', 'kV4EXnugee')
    document.body.appendChild(script)
  }, 3500)
})

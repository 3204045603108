/* eslint-disable no-console */

// import Bowser from 'bowser'
import {
  generateModuleBySlug,
  getCleanCategoriesList,
  getCleanItem, getCleanItemForArticle,
  getCleanItemList,
  getCleanStyleList,
  makeTree
} from '@/helpers/utils'
import getContrastColor from '../helpers/getContrastColor'

export const state = () => ({
  colors: null,
  categories: null,
  description: null,
  tags: null,
  items: null,
  randomItems: null,
  styles: null,
  salons: null,
  adThemes: null,
  brands: null,
  buyDress: null,
  buyDressPrefix: null,
  dayItems: {},
  articles: [],
  reviews: [],
  favoriteItems: [],
  searchItems: [],
  searchQuery: null,
  currentCategory: null,
  currentStyles: [],
  seoEntities: null,
  currentBrand: null,
  currentColor: null,
  currentPage: 0,
  firstPage: 0,
  currentMinPrice: null,
  currentMaxPrice: null,
  currentCategoryMinPrice: 0,
  currentCategoryMaxPrice: 100000,
  debugWeinreId: null,
  bookingFormOpened: false,
  requestCallAnalyticType: null,

  filterColors: []
})

export const mutations = {
  setBrands (state, list) {
    state.brands = list.map((item) => {
      return { ...item, description: null }
    })
  },
  setColors (state, list) {
    state.colors = list.map((item) => {
      return {
        ...item,
        contrastCode: getContrastColor(item.code)
      }
    })
  },
  setCategories (state, list) {
    state.categories = getCleanCategoriesList(list)
  },
  setTags (state, list) {
    state.tags = list
  },
  setAdverts (state, list) {
    state.adverts = list
  },
  setSearchItems (state, list) {
    state.searchItems = list
  },
  setBuyDress (state, value) {
    state.buyDress = value
  },
  setBuyDressPrefix (state, value) {
    state.buyDressPrefix = value
  },
  setCurrentCategoryMinPrice (state, value) {
    state.currentCategoryMinPrice = value
  },
  setCurrentCategoryMaxPrice (state, value) {
    state.currentCategoryMaxPrice = Math.ceil(value / 5000) * 5000
  },
  setCurrentMinPrice (state, value) {
    state.currentMinPrice = value
  },
  setCurrentMaxPrice (state, value) {
    state.currentMaxPrice = value
  },
  setFilterColors (state, value) {
    state.filterColors = value
  },
  addSearchItems (state, list) {
    if (!state.searchItems) {
      state.searchItems = getCleanItemList(list)
    } else {
      state.searchItems = state.searchItems.concat(getCleanItemList(list))
    }
  },
  setSearchQuery (state, query) {
    state.searchQuery = query
  },
  setItems (state, list) {
    state.items = getCleanItemList(list)
  },
  setCurrentBrand (state, brand) {
    state.currentBrand = brand
  },
  setCurrentStyles (state, styles) {
    state.currentStyles = styles
  },
  setCurrentCategory (state, category) {
    state.currentCategory = category
  },
  setCurrentColor (state, color) {
    state.currentColor = color
  },
  setCurrentPage (state, page) {
    state.currentPage = page
  },
  setFirstPage (state, page) {
    state.firstPage = page
  },
  addItems (state, list) {
    if (!state.items) {
      state.items = getCleanItemList(list)
    } else {
      state.items = state.items.concat(getCleanItemList(list))
    }
  },
  setRandomItems (state, list) {
    state.randomItems = getCleanItemList(list)
  },
  setEntitiesSeo (state, list) {
    state.seoEntities = list
  },
  setDayItems (state, { category, list }) {
    state.dayItems[category] = list
  },
  setStyles (state, list) {
    state.styles = getCleanStyleList(list)
  },
  setSalons (state, list) {
    state.salons = list
  },
  addArticles (state, list) {
    const articlesSet = new Map(state.articles.map(article => [article.id, article]))

    for (let i = 0; i < list.length; i++) {
      articlesSet.set(list[i].id, list[i])
    }

    state.articles = Array.from(articlesSet.values())
  },
  addReviews (state, list) {
    const reviewsSet = new Map(state.reviews.map(review => [review.id, review]))

    for (let i = 0; i < list.length; i++) {
      reviewsSet.set(list[i].id, list[i])
    }

    state.reviews = Array.from(reviewsSet.values())
  },
  setFavoriteItems (state, list) {
    state.favoriteItems = list
  },
  setDebugWeinreId (state, id) {
    state.debugWeinreId = id
  },
  setBookingFormOpened (state, flag) {
    state.bookingFormOpened = flag
  },
  setRequestCallAnalyticType (state, type) {
    state.requestCallAnalyticType = type
  }
}

export const getters = {
  tree (state) {
    return makeTree(state.categories, null, item => item, (item) => {
      return item.active
    })
  },
  isConsoleActive (state) {
    return !!state.debugWeinreId || process.env.NODE_ENV !== 'production'
  },
  isApplyFilters (state) {
    return (
      state.currentColor ||
      state.currentStyles ||
      state.currentBrand ||
      (exists(state.currentMinPrice) && exists(state.currentMaxPrice))
    )
  },
  filteredByString (state) {
    const filterStringArray = []
    if (state.currentStyles) {
      filterStringArray.push('стилю')
    }
    if (state.currentBrand) {
      filterStringArray.push('бренду')
    }
    if (state.currentColor) {
      filterStringArray.push('цвету')
    }
    if (
      (state.currentMinPrice !== null || state.currentMaxPrice !== null) &&
      (state.currentMinPrice !== state.currentCategoryMinPrice ||
        state.currentMaxPrice !== state.currentCategoryMaxPrice)
    ) {
      filterStringArray.push('цене')
    }

    return filterStringArray.join(filterStringArray.length === 2 ? ' и ' : ', ')
  },
  getItems (state) {
    if (!(state.items && state.items.length)) {
      return []
    }

    return state.items
  },
  getFavoriteItems (state) {
    if (!(state.favoriteItems && state.favoriteItems.length)) {
      return []
    }

    return state.favoriteItems
  },
  getSearchItems (state) {
    if (!(state.searchItems && state.searchItems.length)) {
      return []
    }

    return state.searchItems
  }
}

export const actions = {
  async nuxtServerInit ({ dispatch, commit }, { req, store }) {
    await dispatch('loadAllEntities', store)
    const querystring = require('querystring')
    const params = querystring.decode(req?._parsedUrl?.query ?? {})
    if (params && params.dbgid) {
      commit('setDebugWeinreId', params.dbgid)
    }
    if (params && (params.booking_form === '' || params.booking_form)) {
      commit('setBookingFormOpened', true)
    }

    /* const browser = Bowser.getParser(req.headers['user-agent'])
    const isValidBrowser = browser.satisfies({
      windows: {
        'internet explorer': '>10'
      },
      macos: {
        safari: '>10.1'
      },
      chrome: '>55',
      firefox: '>49',
      opera: '>=22',
      edge: '>40'
    })

    if (!isValidBrowser && req.url !== '/browser/') {
      redirect('/browser/')
    } */
  },
  resetCurrents ({ commit }) {
    commit('setCurrentCategory', null)
    commit('setCurrentColor', null)
    commit('setCurrentBrand', null)
    commit('setCurrentStyles', [])
    commit('setCurrentMinPrice', null)
    commit('setCurrentMaxPrice', null)
    commit('setCurrentMaxPrice', null)
    commit('setCurrentCategoryMinPrice', 0)
    commit('setCurrentCategoryMaxPrice', 100000)
    commit('setFilterColors', [])
  },
  async loadArticle ({ state, commit, dispatch }) {},
  async loadAllEntities ({ state, commit, dispatch }, store) {
    if (!state.seoEntities) {
      const seo = await this.$axios.$get('/entityseos?_limit=-1')
      commit('setEntitiesSeo', seo)
    }

    let categories = []

    if (!state.categories) {
      categories = await this.$axios.$get('/categories?_limit=-1&active=1')

      categories.forEach((category) => {
        const module = generateModuleBySlug(category.slug)
        store.registerModule(category.slug, module)
      })

      commit('setCategories', categories)
    }

    if (!state.tags) {
      const tags = await this.$axios.$get('/tags?_limit=-1')
      commit('setTags', tags)
    }

    if (!state.colors) {
      const colors = await this.$axios.$get('/colors/all')
      commit('setColors', colors)

      categories.forEach((cat) => {
        const colorsOfCategory = state.colors.filter(color => color.categories.includes(cat.id))
        if (colorsOfCategory.length) {
          commit(`${cat.slug}/set_${cat.slug}_Colors`, colorsOfCategory)
        }
      })
    }

    if (!state.styles) {
      const styles = await this.$axios.$get('/styles/all')
      commit('setStyles', styles)

      categories.forEach((cat) => {
        const stylesOfCategory = styles.filter(style => style.categories.includes(cat.id))
        if (stylesOfCategory.length) {
          commit(`${cat.slug}/set_${cat.slug}_Styles`, stylesOfCategory)
        }
      })
    }

    if (!state.salons) {
      const salons = await this.$axios.$get('/salons?_limit=-1')
      commit('setSalons', salons)
    }

    if (!state.brands) {
      const brands = await this.$axios.$get('/brands/all')
      commit('setBrands', brands)

      categories.forEach((cat) => {
        const brandsOfCategory = brands.filter(
          brand =>
            !!brand.sorting.find((item) => {
              return item.category === cat.id
            })
        )
        if (brandsOfCategory.length) {
          commit(`${cat.slug}/set_${cat.slug}_Brands`, brandsOfCategory)
        }
      })
    }

    if (!state.adverts) {
      const adverts = await this.$axios.$get('/adverts?_limit=-1&active=true&_sort=sort:asc')
      commit('setAdverts', adverts)
    }
  },

  async countItems (
    { state, commit },
    { categorySlug, brandSlug, styleSlugs, searchNameQuery, colorId, priceMin, priceMax }
  ) {
    let url = '/items/count'
    if (exists(categorySlug)) {
      url += `/category/${categorySlug}`
    }
    if (exists(brandSlug)) {
      url += `/${brandSlug}`
    }

    url += '?active=1'

    if (exists(styleSlugs)) {
      for (const styleSlug of styleSlugs) {
        url += `&style=${styleSlug}`
      }
    }
    if (exists(colorId)) {
      url += `&color=${colorId}`
    }
    if (exists(priceMin)) {
      url += `&price_gt=${priceMin}`
    }
    if (exists(priceMax)) {
      url += `&price_lt=${priceMax}`
    }

    if (exists(searchNameQuery)) {
      if (typeof searchNameQuery !== 'object') {
        searchNameQuery = [searchNameQuery]
      }
      url += `&name_contains=${searchNameQuery
        .map(query => encodeURIComponent(query.toLowerCase()))
        .join('&name_contains=')}`
    }

    let answer = 0

    try {
      answer = await this.$axios.$get(url)
    } catch (err) {
      handleError(err)
    }

    return Number(answer)
  },

  async getFilters (
    { commit },
    { categoryId, brandId, stylesId, searchNameQuery, colorId, priceMin, priceMax }
  ) {
    let url = '/filters?'
    if (exists(categoryId)) {
      url += `category=${categoryId}`
    }
    if (exists(brandId)) {
      url += `&brand=${brandId}`
    }

    if (exists(stylesId)) {
      for (const styleId of stylesId) {
        url += `&style=${styleId}`
      }
    }
    if (exists(colorId)) {
      url += `&color=${colorId}`
    }
    if (exists(priceMin)) {
      url += `&price_gt=${priceMin}`
    }
    if (exists(priceMax)) {
      url += `&price_lt=${priceMax}`
    }

    if (exists(searchNameQuery)) {
      if (typeof searchNameQuery !== 'object') {
        searchNameQuery = [searchNameQuery]
      }
      url += `&search=${searchNameQuery
        .map(query => encodeURIComponent(query.toLowerCase()))
        .join('&search=')}`
    }

    let answer = []

    try {
      answer = await this.$axios.$get(url)
    } catch (err) {
      handleError(err)
    }

    commit('setFilterColors', answer.colors)
  },

  async loadItemById ({ state, commit }, id) {
    try {
      const item = await this.$axios.$get(
          `/items/${id}`
      )
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        // console.log('items loaded', items)
      }
      return getCleanItemForArticle(item)
    } catch (err) {
      handleError(err)
    }
  },
  async loadItem ({ state, commit }, { itemSlug, brandSlug, categorySlug }) {
    try {
      const items = await this.$axios.$get(
        `/items/category/${categorySlug}/${brandSlug}?_limit=1&item_slug=${itemSlug}`
      )
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        // console.log('items loaded', items)
      }
      return getCleanItem(items[0])
    } catch (err) {
      handleError(err)
    }
  },
  loadPricesByCategory ({ state, commit }, categorySlug) {
    return this.$axios.$get(`/categories/${categorySlug}/prices`)
  },
  loadMainPageItems ({ state, commit }, categorySlug) {
    return this.$axios.$get(`/items/day/${categorySlug}`)
  },
  async loadItems (
    { state, commit },
    { itemIds, searchNameQuery, categorySlug, brandSlug, styleSlugs, skip, limit, colorId, priceMin, priceMax }
  ) {
    // commit('setItems', [])

    let url = '/items'
    if (exists(categorySlug)) {
      url += `/category/${categorySlug}`

      if (exists(brandSlug)) {
        url += `/${brandSlug}`
      }
    }

    if (
      exists(skip) ||
      exists(limit) ||
      exists(styleSlugs) ||
      exists(colorId) ||
      (exists(priceMin) && exists(priceMax))
    ) {
      url += '?active=1'
      if (exists(skip)) {
        url += `&_start=${skip}`
      }
      if (exists(limit)) {
        url += `&_limit=${limit}`
      }
      if (exists(styleSlugs)) {
        for (const styleSlug of styleSlugs) {
          url += `&style=${styleSlug}`
        }
      }
      if (exists(colorId)) {
        url += `&color=${colorId}`
      }
      if (exists(priceMin)) {
        url += `&price_gt=${priceMin}`
      }
      if (exists(priceMax)) {
        url += `&price_lt=${priceMax}`
      }
    }

    try {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.log('items load by url', url)
      }
      let items = await this.$axios.$get(url)
      items = items.map(item => getCleanItem(item))

      // commit('setItems', items)
      commit('addItems', items)
      return items
    } catch (err) {
      handleError(err)
    }
  },

  async loadRandomItems ({ state, commit }, { limit, exclude }) {
    let url = '/items/random'

    if (exclude || limit) {
      url += '?'
      if (limit) {
        url += `_limit=${limit}`
      }
      if (exclude) {
        url += `&exclude=${exclude}`
      }
    }

    try {
      let items = await this.$axios.$get(url)
      items = items.map(item => getCleanItem(item))
      commit('setRandomItems', items)
      return items
    } catch (err) {
      handleError(err)
    }
  },

  async loadFavoriteItems ({ state, commit }, { itemIds, limit }) {
    commit('setFavoriteItems', [])

    let url = '/items'

    if (limit) {
      url += '?'
      if (limit) {
        url += `&_limit=${limit}`
      }
    }

    if (itemIds) {
      if (typeof itemIds !== 'object') {
        itemIds = [itemIds]
      }
      url += `&id=${itemIds.join('&id=')}`
    }

    try {
      let items = await this.$axios.$get(url)
      items = items.map(item => getCleanItem(item))
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        // console.log('items loaded by url', url, items.length)
      }
      commit('setFavoriteItems', items)
      return items
    } catch (err) {
      handleError(err)
    }
  },
  async loadSearchItems ({ state, commit }, { searchNameQuery, limit, skip }) {
    let url = `/search/${encodeURIComponent(searchNameQuery.toLowerCase())}`

    if (skip || limit) {
      url += '?'
      if (skip) {
        url += `&_start=${skip}`
      }
      if (limit) {
        url += `&_limit=${limit}`
      }
    }

    try {
      let items = await this.$axios.$get(url)
      items = items.map(item => getCleanItem(item))
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.log('items search loaded by url', url, items.length)
      }
      commit('addSearchItems', items)
      return items
    } catch (err) {
      handleError(err)
    }
  }
}

function handleError (error) {
  if (process.env.NODE_ENV !== 'production') {
    if (error.response) {
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      console.log(error.request)
    } else {
      console.error('Error', error.message)
    }
    console.log(error.config)
  }

  return error
}

function exists (variable) {
  return (typeof variable !== 'undefined' && variable !== null) || variable === 0
}
